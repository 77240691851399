import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, ToggleButtonGroup, ToggleButton, Stack } from '@mui/material';
import { Listing, OlxStatus, Platform } from './models/listing';

interface DeleteListingDialogProps {
  open: boolean;
  listing: Listing;
  onClose: (deletionParameters?: { listing: Listing, platforms: Platform[] }) => void;
}

const PLATFORMS = [Platform.Main, Platform.Vendora, Platform.Olx] as const;

type PlatformDeletionParameters = { [key in Platform]: boolean };

export const DeleteListingDialog = ({ open, onClose, listing }: DeleteListingDialogProps) => {
  const [platformDeleteOptions, setPlatformDeleteOptions] = useState<PlatformDeletionParameters>({
    main: false,
    vendora: false,
    olx: false
  });

  const getInfoTextForPlatform = (platform: Platform, selectedOption: boolean): string => {
    switch (platform) {
      case Platform.Main:
        return selectedOption ? 'Обявата ще бъде изтрита от Expanda.' : 'Обявата ще остане в Expanda.';
      case Platform.Vendora:
        return selectedOption ? 'Обявата ще бъде изтрита от Vendora. Това може да отнеме до 1ч.' : 'Обявата ще остане във Vendora.';
      case Platform.Olx:
        return selectedOption ? 'Обявата ще бъде деактивирана в OLX.' : 'Обявата ще остане в OLX.';
    }
  }

  const getActionTextForPlatform = (platform: Platform): string => {
    switch (platform) {
      case Platform.Main:
        return 'Изтриване от Expanda:';
      case Platform.Vendora:
        return 'Изтриване от Vendora:';
      case Platform.Olx:
        return 'Деактивиране в OLX:';
    }
  }
  const isListingDeletable = (platform: Platform) => {
    if (platform === Platform.Olx) {
      return isOlxListingDeletable;
    } else if (platform === Platform.Vendora) {
      const vendoraCrossListing = listing.crossListings?.vendora;
      return !!vendoraCrossListing &&
        vendoraCrossListing.status !== 'deleted' &&
        vendoraCrossListing.status !== 'not_shared';
    }
    return true;
  }

  const getDeletablePlatforms = () => {
    return PLATFORMS.filter(platform => isListingDeletable(platform as Platform));
  };

  const onlyMainSelectedToDelete = () => {
    const platformToDelete = getDeletablePlatforms();
    return platformToDelete.length === 1 && platformToDelete[0] === Platform.Main;
  };

  const getPlatformsToDelete = (): Platform[] => {
    if (onlyMainSelectedToDelete()) {
      return [Platform.Main];
    }
    return PLATFORMS.filter(key => !!platformDeleteOptions[key]) as Platform[];
  }

  const updatePlatformDeleteOptions = (platform: Platform, markedForDeletion: boolean | null) => {
    if (markedForDeletion === null) {
      return;
    }
    // The Vendora listing cannot be used without the main listing
    const canDeleteVendoraListing = getDeletablePlatforms().includes(Platform.Vendora);
    const vendoraNotMarkedForDeletion = !platformDeleteOptions.vendora;
    const mainFormMarkedForDeletion = platform === Platform.Main && markedForDeletion;

    if ((mainFormMarkedForDeletion && vendoraNotMarkedForDeletion && canDeleteVendoraListing) ||
      (platform === Platform.Vendora && !markedForDeletion && !!platformDeleteOptions.main)
    ) {
      setPlatformDeleteOptions({ ...platformDeleteOptions, [Platform.Main]: true, [Platform.Vendora]: true });
      return;
    }
    setPlatformDeleteOptions({ ...platformDeleteOptions, [platform]: markedForDeletion });
  }

  const isOlxListingDeletable = (listing.externalIdentifiers?.olx
    && listing.crossListings?.olx?.status === OlxStatus.Active)
    || listing.crossListings?.olx?.status === OlxStatus.New
    || listing.crossListings?.olx?.status === OlxStatus.Disabled;

  const deleteListings = () => {
    setPlatformDeleteOptions({ olx: false, vendora: false, main: false });
    onClose({ listing, platforms: getPlatformsToDelete() });
  }

  const renderPlatformDeleteOptions = PLATFORMS.filter(platform => isListingDeletable(platform as Platform)).map((platform) =>
    <Stack direction='column' alignItems='center' justifyContent='center'>
      <Typography variant='body1'>{getActionTextForPlatform(platform)}</Typography>
      <ToggleButtonGroup
        value={platformDeleteOptions[platform]}
        exclusive
        onChange={(_, markedForDeletion) => updatePlatformDeleteOptions(platform as Platform, markedForDeletion)}
        key={platform}
      >
        <ToggleButton color='error' value={true} aria-label="yes">
          Да
        </ToggleButton>
        <ToggleButton value={false} aria-label="yes">
          Не
        </ToggleButton>
      </ToggleButtonGroup>
      <Typography variant='caption'>{getInfoTextForPlatform(platform, platformDeleteOptions[platform])}</Typography>
    </Stack >
  );

  return (
    <Dialog fullWidth={true} open={open} onClose={() => onClose()}>
      <DialogTitle alignSelf={'center'}>Откъде искаш да премахнеш обявата?</DialogTitle>
      <DialogContent>
        <Stack direction='column' alignItems='center' spacing={2}>
          {
            <>
              {renderPlatformDeleteOptions}
            </>
          }
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>
          Отмени
        </Button>
        <Button
          onClick={() => deleteListings()}
          variant="contained"
          disabled={!onlyMainSelectedToDelete() && Object.values(platformDeleteOptions).every(value => !value)}
          color="error">
          ОК
        </Button>
      </DialogActions>
    </Dialog>
  );
};