import { ErrorMessage, FormikProvider, useFormik } from 'formik';
import { Listing, ListingUpdateData, Platform } from './models/listing';
import { Image } from './models/image';
import { useEffect, useState } from 'react';
import { RemoteImage, isLocalImage } from './models/image';
import { not } from './utils/functions';
import { convertAttributesForSubmission } from './models/category-specific-attribute';
import { TextField, InputAdornment, Box, Stack, Typography } from '@mui/material';
import { AttributesForm } from './AttributesForm';
import { CategorySelector } from './CategorySelector';
import { ImageInput } from './ImageInput';
import { LocationSelector } from './LocationSelector';
import createAuthRequest from './utils/createAuthRequest';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { FormFields, getInitialFormValues } from './models/main-form-fields';
import { GenericObjectSchema, mainFormValidationSchema } from './utils/validation-schemas';
import { useSnackbar } from './utils/error-toast-provider';
import { FormSubmitButton } from './FormSubmitButton';
import { LoadingWrapper } from './components/LoadingWrapper';
import { useDebounce } from 'use-debounce';

type Props = {
  existingListing?: Listing;
  listingUpdated: (updatedListing: Listing | undefined) => void;
};

export const MainListingForm = ({ existingListing, listingUpdated }: Props) => {
  const formValues: FormFields = {
    ...getInitialFormValues(existingListing),
    images: existingListing?.images ?? []
  };

  const [validationSchema, setValidationSchema] = useState(mainFormValidationSchema);
  const [attributesValidationSchemaApplied, setAttributesValidationSchemaApplied] = useState(false);

  const addAttributesToValidationSchema = (attributesSchema: GenericObjectSchema) => {
    setValidationSchema((mainFormValidationSchema as any).concat(attributesSchema));
  };

  useEffect(() => {
    if ((validationSchema as GenericObjectSchema).fields.attributes) {
      setAttributesValidationSchemaApplied(true);
    }
  }, [validationSchema]);

  const { showSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: formValues,
    onSubmit: (values) => mutation.mutate(values),
    validationSchema,
    validateOnMount: true
  });

  useEffect(() => {
    formik.setValues(formValues, false);
  }, [existingListing]);

  const navigate = useNavigate();

  const prepareRequestBody = ({ title, price, description, category, city, district }: FormFields): ListingUpdateData | undefined => {
    const existingImages = formik.values.images.filter(not(isLocalImage)) as RemoteImage[];
    return {
      title,
      description,
      price,
      category,
      location: {
        cityId: city,
        districtId: district
      },
      attributes: convertAttributesForSubmission(formik.values.attributes),
      modifiedImages: existingImages
    };
  };

  const prepareImagesForUpload = (): FormData => formik.values.images
    .filter(isLocalImage)
    .reduce((formData, image) => {
      formData.append('images[]', image.data);
      formData.append('order[]', image.order.toString());
      return formData;
    }, new FormData());

  const createOrUpdateListing = async (formValues: FormFields): Promise<Listing | undefined> => {
    try {
      const listing = await createAuthRequest<Listing>('listings', !!existingListing ? 'PUT' : 'POST')
        (existingListing?._id, undefined, prepareRequestBody(formValues));
      if (!listing) {
        return;
      }
      if (!formik.values.images.some(isLocalImage)) {
        return listing;
      }
      const listingWithImages = await createAuthRequest<Listing>(`listings/${listing?._id}/images`, 'PUT')
        (undefined, undefined, prepareImagesForUpload());
      return listingWithImages;
    } catch (e) {
      return;
    }
  }

  const mutation = useMutation({
    mutationFn: createOrUpdateListing,
    onSuccess: (response) => {
      if (!response) {
        return;
      }
      showSnackbar(`Обявата беше успешно ${!!existingListing ? 'редактирана' : 'създадена'}`, 'success');
      if (!!existingListing && !!response) {
        listingUpdated(response);
      } else {
        navigate(`/listings/${response._id}/edit/main`);
      }
    },
  });

  const [title] = useDebounce(formik.values.title, 200);

  const queryClient = useQueryClient();
  const isListingBlockedForchanges = (): boolean => {
    const queryKeys = [
      ['categories', 'taxonomy/categories'],
      ['cities', 'taxonomy/cities'],
      ['districts', `taxonomy/cities/${formik.values.city}/districts`],
      ['attributes', formik.values.category],
      ['suggestions', title]
    ];

    const isQueryPending = queryKeys.some(query => queryClient.getQueryState(query)?.status === 'pending');

    return isQueryPending
      || mutation.isPending;
  }

  return (
    <FormikProvider value={formik}>
      <Stack width='95%' gap={2}>
        {!existingListing && <Typography variant='h4' component='h3'>Създай обява</Typography>}
        <Box>
          <ImageInput
            existingImages={formik.values.images.filter(not(isLocalImage)) as RemoteImage[]}
            imagesChanged={(images: Image[]) => { formik.setFieldTouched('images'); formik.setFieldValue('images', images) }}
          />
          <ErrorMessage name="images" />
        </Box>

        <TextField
          type='text'
          name='title'
          variant='outlined'
          label='Заглавие'
          multiline={true}
          rows={Math.ceil(formik.values.title.length / 40)}
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.title && !!formik.errors.title}
          helperText={formik.touched.title && formik.errors.title}
        />

        <TextField
          type='textarea'
          multiline={true}
          minRows={10}
          maxRows={70}
          style={{ minWidth: '300px' }} // important, as otherwise we get a cryptic error
          // https://github.com/mui/base-ui/issues/167
          title='Описание'
          label='Описание'
          name='description'
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.description && !!formik.errors.description}
          helperText={formik.touched.description && formik.errors.description}
        />

        {
          title &&
          <CategorySelector
            listingTitle={title}
            isEditing={!!existingListing}
            onCategorySelected={(selectedCategory) => formik.setFieldValue('category', selectedCategory.data.id)}
            preselectedCategoryId={formik.initialValues.category}
            suggestionsEndpoint='taxonomy/categories/suggestions'
            marketplace={Platform.Olx}
          />
        }

        {
          !!formik.values.category &&
          <AttributesForm
            selectedCategory={formik.values.category}
            initialValues={formik.initialValues.attributes}
            endpoint={(selectedCategory: string) => `taxonomy/categories/${selectedCategory}/attributes`}
            attributesValidationSchemaLoaded={addAttributesToValidationSchema}
            attributesValidationSchemaApplied={attributesValidationSchemaApplied}
          />
        }

        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent='space-evenly' alignItems={{ xs: 'center' }} spacing={{ xs: 2, sm: 2 }}>
          <LocationSelector
            endpoint='taxonomy/cities'
            formField='city'
            locationTypeLabel='Град'
            preselectedLocationId={formik.initialValues.city}
          />

          {formik.values.city && <LocationSelector
            endpoint={`taxonomy/cities/${formik.values.city}/districts`}
            formField='district'
            locationTypeLabel='Квартал'
            preselectedLocationId={formik.initialValues.district}
          />}

          <TextField
            type='number'
            label='Цена'
            title='Цена'
            name='price'
            value={formik.values.price}
            InputProps={{
              endAdornment: <InputAdornment position='end'>лв</InputAdornment>
            }}
            sx={{ flex: 1, width: '100%' }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.price && !!formik.errors.price}
            helperText={formik.touched.price && formik.errors.price}
          />
        </Stack>

        {<LoadingWrapper loading={isListingBlockedForchanges()}>
          <FormSubmitButton
            title={!!existingListing ? 'Запази' : 'Създай'}
            onClick={() => formik.handleSubmit()}
          />
        </LoadingWrapper>}
      </Stack>
    </FormikProvider >
  );
};
